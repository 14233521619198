
<template>
    <div class="h-screen flex w-full bg-img">
        <div class="vx-col flex items-center justify-center flex-col sm:w-1/2 md:w-3/5 lg:w-3/4 xl:w-1/2 mx-auto text-center">
            <img :src="`${$store.state.domain}/images/glass-lines-loading.gif`" alt="loading..."/>
            <h5 class="sm:mx-0 mx-4 my-3 d-theme-heading-color">Authenticating ...!</h5>
            <p class="text-center mb-3">You will be redirected shortly</p>
        </div>
    </div>
</template>

<script>

export default {
    async created(){
        if(!this.$route.query.token){
          location.replace(this.$store.state.domain);return
        }
        let token = JSON.parse(atob(this.$route.query.token));
        if(typeof token !== 'object' && token === null){
          location.replace(this.$store.state.domain);return
        }
        let formData = new FormData();
        formData.append('data', JSON.stringify(token));
        this.$http.post(`${this.$store.state.domain}/api/auto-login`, formData)
        .then((response)=> {
        if(response.data.success){
            const expirationTime = new Date( new Date().getTime() + +response.data.expiresIn * 1000);
            const config = {
              role: response.data.role,
              token: response.data.accessToken,
              tokenExpirationDate: expirationTime.toISOString(),
              user: response.data.user,
            }
            const encryptedValue = btoa(escape(JSON.stringify(config)));
            localStorage.setItem(this.$store.state.localUserKey, encryptedValue);
            window.location = `${location.origin}${this.$route.query.intended}`;
        }else{
            location.replace(this.$store.state.domain)
        }
        }).catch(err => { console.error(err) })

    }
}
</script>
